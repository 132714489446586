import * as React from 'react'
import { graphql } from 'gatsby'
import PortfolioIndex from '../../components/portfolio-index'


const PortfolioPage = ({ data }) => {

  return (
    <PortfolioIndex worktype="live" pageTitle="Live Event Work" data={data} />
  )

}


export const query = graphql`
  {
    allMdx(sort: {fields: frontmatter___displayorder, order: ASC}
      filter: {frontmatter: {published: { eq: true }, worktypes: {in: "live"}}}) {
      nodes {
        frontmatter {
          title
          subtitle
          worktypes
          cover_image {
            childImageSharp {
              gatsbyImageData(
                  placeholder: BLURRED
                  width: 400
              )
            }
          }
          cover_image_alt
        }
        id
        slug
      }
    }
  }
`


export default PortfolioPage